import React, { useEffect } from "react";
import { Link } from "react-router-dom";

//* Imported Images
import aboutImg1 from "./imgs/aboutImage1.jpeg";
import aboutImg2 from "./imgs/aboutImage2.jpg";
import img1 from "./imgs/img1.png";
import img2 from "./imgs/img2.png";

// Aos
import Aos from "aos";
import "aos/dist/aos.css";
import { useTranslation } from "react-i18next";

//* about us Section Content
const aboutContent = [
  {
    img: aboutImg1,
    title: "Over 300+ Complete Works And Counting",
    description:
      "Stellar is an Egyptian company specializing in the field of solar energy for homes, companies, schools, and institutions for more than 10 years. We rely on a unique method in installing our systems with precise engineering standards, and we seek to revolutionize the world of sustainable energy by offering clean and economical alternatives. Our history is linked to the idea of providing… Alternative energy solutions with increasing energy prices. Our company is distinguished by the technical aspects, quality and commercial aspect. Our founders are specialized engineers who possess technical experience and business skills. They reflect our vision of achieving positive change towards alternative and sustainable energy sources. We plan to have an effective impact on the Egyptian and Arab markets by using innovative strategies to develop the market and our products",
  link: "Learn more about us",
  },
  {
    img: aboutImg2,
    title:
      "More Than 10 Years Of Service In The Field Of Solar And Renewable Energy",
    description:
      "Stellar is the only company and factory in the Arab world that manufactures solar water heaters at the lowest cost and with the highest possible quality. The company is distinguished by manufacturing the highest quality solar water heaters in the Arab world. With more than 10 years of experience, we have manufactured more than 350 solar water heaters for institutions and companies in Egypt.",
    link: "Learn more about us",
  },
];
const finallySec = {
  img1: img1,
  img2: img2,
};

const AboutUs = () => {

  const [t] = useTranslation("global");

  useEffect(() => {
    Aos.init({
      duration: 1300,
    });
  }, []);
  return (
    <div>
      <section class="text-gray-700 body-font">
        <div
          data-aos="fade-up"
          class="title flex justify-center text-[--primary-color] mt-10 text-4xl font-regular">
          {t("Why Us?")}
        </div>
        <div class="container px-5 py-12 mx-auto">
          <div class="flex flex-wrap text-center justify-center">
            <div data-aos="fade-right" class="p-4 md:w-1/4 sm:w-1/2">
              <div class="px-4 py-6 transform transition duration-500 hover:scale-110">
                <div class="flex justify-center">
                  <img
                    src="https://image3.jdomni.in/banner/13062021/58/97/7C/E53960D1295621EFCB5B13F335_1623567851299.png?output-format=webp"
                    class="w-32 mb-3"
                    alt="img"
                  />
                </div>
                <h2 class="title-font font-regular text-2xl text-gray-900">
                  {t("Latest Milling Machinery")}
                </h2>
              </div>
            </div>

            <div data-aos="fade-right" class="p-4 md:w-1/4 sm:w-1/2">
              <div class="px-4 py-6 transform transition duration-500 hover:scale-110">
                <div class="flex justify-center">
                  <img
                    src="https://image2.jdomni.in/banner/13062021/3E/57/E8/1D6E23DD7E12571705CAC761E7_1623567977295.png?output-format=webp"
                    class="w-32 mb-3"
                    alt="img"
                  />
                </div>
                <h2 class="title-font font-regular text-2xl text-gray-900">
                  {t("Reasonable Rates")}
                </h2>
              </div>
            </div>

            <div data-aos="fade-left" class="p-4 md:w-1/4 sm:w-1/2">
              <div class="px-4 py-6 transform transition duration-500 hover:scale-110">
                <div class="flex justify-center">
                  <img
                    src="https://image3.jdomni.in/banner/13062021/16/7E/7E/5A9920439E52EF309F27B43EEB_1623568010437.png?output-format=webp"
                    class="w-32 mb-3"
                    alt="img"
                  />
                </div>
                <h2 class="title-font font-regular text-2xl text-gray-900">
                  {t("Time Efficiency")}
                </h2>
              </div>
            </div>

            <div data-aos="fade-left" class="p-4 md:w-1/4 sm:w-1/2">
              <div class="px-4 py-6 transform transition duration-500 hover:scale-110">
                <div class="flex justify-center">
                  <img
                    src="https://image3.jdomni.in/banner/13062021/EB/99/EE/8B46027500E987A5142ECC1CE1_1623567959360.png?output-format=webp"
                    class="w-32 mb-3"
                    alt="img"
                  />
                </div>
                <h2 class="title-font font-regular text-2xl text-gray-900">
                  {t("Expertise in Industry")}
                </h2>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="bg-gray-100">
        {aboutContent.map((data, i) => {
          return (
            <div key={i} class="container mx-auto py-16 px-4 sm:px-6 lg:px-8">
              <div
                data-aos="fade-right"
                class="grid grid-cols-1 md:grid-cols-2 items-center gap-8">
                <div class="max-w-lg">
                  <h2 className="text-3xl font-extrabold text-gray-900 sm:text-4xl">
                    {t(data.title)}
                  </h2>
                  <p class="mt-4 text-gray-600 text-lg">{t(data.description)}</p>
                  <div class="mt-8">
                    <Link
                       to="/moreAboutUs"
                      class="text-blue-500 bg-slate-200 rounded-lg shadow-xl p-2 text-lg font-bold hover:text-blue-600">
                      {t(data.link)}
                      <span class="ml-2">&#8594;</span>
                    </Link>
                  </div>
                </div>
                <div class="mt-12 md:mt-0">
                  <img
                    src={data.img}
                    alt="About UsImage"
                    class="object-cover rounded-lg shadow-md"
                  />
                </div>
              </div>
            </div>
          );
        })}
      </section>

      <section class="py-24 relative">
        <div class="w-full max-w-7xl px-4 md:px-5 lg:px-5 mx-auto">
          <div class="w-full justify-start items-center gap-12 grid lg:grid-cols-2 grid-cols-1">
            <div
              data-aos="fade-right"
              class="w-full justify-center items-start gap-6 grid sm:grid-cols-2 grid-cols-1 lg:order-first order-last">
              <div class="pt-24 lg:justify-center sm:justify-end justify-start items-start gap-2.5 flex">
                <img
                  loading="lazy"
                  class=" rounded-xl"
                  src={finallySec.img1}
                  alt="aboutUsimage"
                />
              </div>
              <img
                loading="lazy"
                class=" rounded-xl"
                src={finallySec.img2}
                alt="aboutUsimage"
              />
            </div>
            <div
              data-aos="fade-left"
              class="w-full flex-col justify-center lg:items-start items-center gap-10 inline-flex">
              <div class="w-full flex-col justify-center items-start gap-8 flex">
                <div class="w-full flex-col justify-start lg:items-start items-center gap-3 flex">
                  <h2 class="text-gray-900 text-4xl font-bold font-manrope leading-normal lg:text-start text-center">
                    {t("Our factory and company for your success")}
                  </h2>
                  <p class="text-gray-500 text-base font-normal leading-relaxed lg:text-start text-center">
                    {t("Every project we've undertaken has been a collaborative effort, where every person involved has left their mark.Together, we've not only Manufacture and installation ofheater but also built enduring connections that define oursuccess story.")}
                  </p>
                </div>
                <div class="w-full lg:justify-start justify-center items-center sm:gap-10 gap-5 inline-flex">
                  <div class="flex-col justify-start items-start inline-flex">
                    <h3 class="text-gray-900 text-4xl font-bold font-manrope leading-normal">
                      {t("10+")}
                    </h3>
                    <h6 class="text-gray-500 text-base font-normal leading-relaxed">
                      {t("Years of Experience")}
                    </h6>
                  </div>
                  <div class="flex-col justify-start items-start inline-flex">
                    <h4 class="text-gray-900 text-4xl font-bold font-manrope leading-normal">
                      {t("350+")}
                    </h4>
                    <h6 class="text-gray-500 text-base font-normal leading-relaxed">
                      {t("Successful Projects")}
                    </h6>
                  </div>
                  <div class="flex-col justify-start items-start inline-flex">
                    <h4 class="text-gray-900 text-4xl font-bold font-manrope leading-normal">
                      {t("350+")}
                    </h4>
                    <h6 class="text-gray-500 text-base font-normal leading-relaxed">
                      {t("Happy Clients")}
                    </h6>
                  </div>
                </div>
              </div>
              <button class="button sm:w-fit w-full px-3.5 py-2 transition-all duration-700 ease-in-out rounded-lg shadow-[0px_1px_2px_0px_rgba(16,_24,_40,_0.05)] justify-center items-center flex">
                <Link class="px-1.5 text-white text-sm font-medium leading-6">
                  {t("Read More")}
                </Link>
              </button>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default AboutUs;
