import React from "react";
import logo from "../../images/logo2.png";
import { Link } from "react-router-dom";

//* React-icons
import { FaFacebookSquare } from "react-icons/fa";
import { FaInstagramSquare } from "react-icons/fa";
import { FaLinkedinIn } from "react-icons/fa6";
import { useTranslation } from "react-i18next";

//* Links
const links = [
  {
    link: "https://www.facebook.com/stellar1eg",
    icon: FaFacebookSquare,
  },
  {
    link: "https://www.instagram.com/eng_redamaher/",
    icon: FaInstagramSquare,
  },
  {
    link: "https://www.linkedin.com/in/reda-maher-15073325b/",
    icon: FaLinkedinIn,
  },
];

//* Services
const servicesLinks = {
  to: "/dataSheet",
  title: "Data Sheet",
};

//* Company
const companyLinks = [
  {
    to: "/aboutUs",
    title: "About Us",
  },
  {
    to: "/dataSheet",
    title: "Services",
  },
  {
    to: "/contactUs",
    title: "Contact Us",
  },
];

const Footer = () => {

  const [t] = useTranslation("global");

  return (
    <footer class="bg-gray-200">
      <div class="mx-auto max-w-screen-xl space-y-8 px-4 py-16 sm:px-6 lg:space-y-16 lg:px-8">
        <div class="grid grid-cols-1 gap-8 lg:grid-cols-3">
          <div>
            <Link to="/">
              <img className="w-[130px] h-[130px]" src={logo} alt="Logo" />
            </Link>

            <p class="mt-4 max-w-xs text-gray-700">
              {t("Stellar is an Egyptian company and a manufacturer of solar waterheaters worldwide. The company is distinguished by its efficiency, quality, very high experience, and competitive prices worldwide.")}
            </p>

            <ul class="mt-8 flex gap-6">
              {links.map((data, i) => {
                return (
                  <li key={i}>
                    <Link
                      to={data.link}
                      rel="noreferrer"
                      target="_blank"
                      class="text-gray-900 transition-all hover:text-[--primary-color]">
                      <data.icon className="text-2xl" />
                    </Link>
                  </li>
                );
              })}
            </ul>
          </div>

          <div class="grid grid-cols-1 gap-8 sm:grid-cols-2 lg:col-span-2 lg:grid-cols-4">
            <div>
              <p class="font-medium text-2xl text-gray-900">{ t("Services")}</p>

              <ul class="mt-6 space-y-4 text-sm">
                <li>
                  <Link
                    to={servicesLinks.to}
                    class="text-gray-800 text-lg transition hover:text-[--primary-color]">
                    {t(servicesLinks.title)}
                  </Link>
                </li>
              </ul>
            </div>

            <div>
              <p class="font-medium text-2xl text-gray-900">{t("Company")}</p>

              <ul class="mt-6 space-y-4 text-sm">
                {companyLinks.map((data, index) => {
                  return (
                    <li key={index}>
                      <Link
                        to={data.to}
                        class="text-gray-800 text-lg transition hover:text-[--primary-color]">
                        {t(data.title)}
                      </Link>
                    </li>
                  );
                })}
              </ul>
            </div>

            <div>
              <p class="font-medium text-2xl text-gray-900">{ t("Legal")}</p>

              <ul class="mt-6 space-y-4 text-sm">
                <li>
                  <a
                    href="#"
                    class="text-gray-800 text-md transition hover:text-[--primary-color]"
                  >
                    {t("Accessibility")}
                  </a>
                </li>

                <li>
                  <a href="#" class="text-gray-700 transition hover:opacity-75">
                    
                    {t("Returns Policy")}
                  </a>
                </li>

                <li>
                  <a href="#" class="text-gray-700 transition hover:opacity-75">
                    
                    {t("Refund Policy")}
                  </a>
                </li>

                <li>
                  <a href="#" class="text-gray-700 transition hover:opacity-75">
                    {t("Hiring Statistics")}
                  </a>
                </li>
              </ul>
              
            </div>
          </div>
        </div>

        <p class="text-lg text-gray-800">
          &copy; {t("2024. Stellar. All rights reserved")}
        </p>
      </div>
    </footer>
  );
};

export default Footer;
