import { useState } from "react";
import "./Header.css";
import { Link } from "react-router-dom";

//* Icons
import { AiOutlineFire } from "react-icons/ai";
import { CiGlass } from "react-icons/ci";
import { MdDeviceHub } from "react-icons/md";
import { TbBrandShopee } from "react-icons/tb";

import {
  Dialog,
  DialogPanel,
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
  Popover,
  PopoverButton,
  PopoverGroup,
  PopoverPanel,
  Transition,
} from "@headlessui/react";

import {
  ArrowPathIcon,
  Bars3Icon,
  ChartPieIcon,
  CursorArrowRaysIcon,
  FingerPrintIcon,
  SquaresPlusIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";

import {
  ChevronDownIcon,
  PhoneIcon,
  PlayCircleIcon,
} from "@heroicons/react/20/solid";

// Imported Images
import logo from "../../images/logo2.png";
import { useTranslation } from "react-i18next";

const products = [
  {
    name: "Non-Pressurized Heater",
    description: "Control: Digital Control",
    to: "/products",
    icon: AiOutlineFire,
  },
  {
    name: "Pressurized Heater",
    description: "Control: Digital Control",
    to: "/products",
    icon: AiOutlineFire,
  },
  {
    name: "Vacuum Tube",
    description: "HIGH EFFICIENCY",
    to: "/products",
    icon: CiGlass,
  },
  {
    name: "Controller",
    description: "HIGH EFFICIENCY, CONTROL FOR ALL PRODUCT",
    to: "/dataSheet",
    icon: MdDeviceHub,
  },
  {
    name: "All Products",
    description: "See More Our products for Stellar Solar Water Heater",
    to: "/products",
    icon: TbBrandShopee,
  },
];
const callsToAction = [
  { name: "Watch demo", href: "#", icon: PlayCircleIcon },
  { name: "Contact sales", href: "/contactUs", icon: PhoneIcon },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Example() {

  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState("EN");

  const toggleDropdown = () => setIsOpen(!isOpen);
  const handleOptionClick = (value) => {
    setSelectedOption(value);
    setIsOpen(false);
  };
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

    const [t, i18n] = useTranslation("global");

    const handleChangeLanguage = (lang) => {
      i18n.changeLanguage(lang);
  }

  return (
    <header className="bg-[#1963A6] shadow-lg z-50 mb-2">
      <nav
        className="mx-auto flex max-w-7xl items-center justify-between lg:px-8"
        aria-label="Global">
        {/* Logo and Mobile Menu Button */}
        <div className="flex lg:flex-1">
          <Link to="/" className="-m-1.5 p-1.5 after:hidden">
            <img
              className="h-8 w-auto w-[130px] h-[130px]"
              src={logo}
              alt="Logo"
            />
          </Link>
        </div>
        <div className="flex lg:hidden">
          <button
            type="button"
            className="inline-flex items-center justify-center text-xl rounded-md p-2.5 text-gray-100"
            onClick={() => setMobileMenuOpen(true)}>
            <span className="sr-only">Open main menu</span>
            <Bars3Icon className="h-8 w-8" aria-hidden="true" />
          </button>
        </div>

        {/* Desktop Menu */}
        <div className="hidden lg:flex lg:items-center lg:ml-auto">
          <PopoverGroup className="flex gap-x-12">
            {/* Home Link */}
            <Link
              to="/"
              className="homeActive text-md font-semibold w-full leading-6">
              {t("Home")}
            </Link>

            {/* Products Dropdown */}
            <Popover className="relative">
  <PopoverButton className="flex items-center text-gray-50 text-[20px] gap-x-2 text-md font-semibold w-full leading-6">
    {t("Products")}
    <ChevronDownIcon className="h-5 w-5 flex-none" aria-hidden="true" />
  </PopoverButton>

  <Transition
    enter="transition ease-out duration-200"
    enterFrom="opacity-0 translate-y-1"
    enterTo="opacity-100 translate-y-0"
    leave="transition ease-in duration-150"
    leaveFrom="opacity-100 translate-y-0"
    leaveTo="opacity-0 translate-y-1"
  >
    {/* جعل العرض ملء الشاشة لتغطية كل المحتوى */}
    <PopoverPanel className="absolute right-0 top-full z-10 mt-3 w-screen sm:max-w-[90%] overflow-auto max-h-[400px] rounded-3xl bg-white shadow-lg ring-1 ring-gray-900/10">
      <div className="p-4">
        {products.map((item) => (
          <div
            key={item.name}
            className="group relative flex items-center gap-x-4 rounded-lg p-4 text-sm leading-6 hover:bg-gray-200"
          >
            <div className="flex h-11 w-11 flex-none items-center justify-center rounded-lg bg-gray-50 group-hover:bg-white">
              <item.icon
                className="h-6 w-6 text-gray-600 group-hover:text-indigo-600"
                aria-hidden="true"
              />
            </div>
            <div className="flex-auto">
              <Link to={item.to} className="block font-semibold hover:text-indigo-700">
                {item.name}
                <span className="absolute inset-0" />
              </Link>
              <p className="mt-1 text-gray-600">{item.description}</p>
            </div>
          </div>
        ))}
      </div>
      <div className="grid grid-cols-2 divide-x divide-gray-200 bg-gray-50">
        {callsToAction.map((item) => (
          <Link
            key={item.name}
            to={item.href}
            className="flex items-center justify-center gap-x-2.5 p-3 text-sm font-semibold leading-6 hover:bg-gray-100"
          >
            <item.icon className="h-5 w-5 flex-none text-gray-400" aria-hidden="true" />
            {item.name}
          </Link>
        ))}
      </div>
                </PopoverPanel>
                
  </Transition>
</Popover>


            {/* Features Link */}
            <Link
              to="/dataSheet"
              id="features"
              className="text-md font-semibold w-full leading-6">
              {t("Features")}
            </Link>

            {/* About Us Link */}
            <Link
              to="/aboutUs"
              className="text-md font-semibold w-full whitespace-nowrap leading-6">
              {t("AboutUs")}
            </Link>

            {/* Contact Us Link */}
            <Link
              to="/contactUs"
              className="text-md font-semibold leading-6 whitespace-nowrap">
              {t("ContactUs")}
            </Link>
          </PopoverGroup>

          {/* Language Dropdown */}
          <div className="relative langs ml-4 mr-4">
            <button
              onClick={toggleDropdown}
              className="w-full text-[18px] bg-white border-2 border-[#1963A6] text-gray-800 py-2 px-4 rounded-lg shadow-lg flex justify-between items-center">
              <span>
                {t("Lang")}
              </span>
              <svg
                className={`w-4 h-4 transition-transform transform ${
                  isOpen ? "rotate-180" : "rotate-0"
                }`}
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M19 9l-7 7-7-7"></path>
              </svg>
            </button>
            {isOpen && (
              <ul className="absolute w-full bg-white border-2 border-[#1963A6] rounded-lg mt-2 shadow-lg z-10">
                <li
                  style={{fontSize:"18px", padding:"5px"}}
                  onClick={() => handleChangeLanguage("en")}
                    className={`cursor-pointer py-2 px-4 hover:bg-[#1963A6] hover:text-white `}>
                    English
                  </li>
              
                <li
                  style={{fontSize:"18px" ,padding:"5px"}}
                  onClick={() => handleChangeLanguage("ar")}
                    className={`cursor-pointer py-2 px-4 hover:bg-[#1963A6] hover:text-white `}>
                    العربية
                  </li>
              
              </ul>
            )}
          </div>

          {/* Shopify Icon */}
          <Link to="/products" className="ml-5 hover:after:w-0">
            <TbBrandShopee
              title="Shop Now"
              className="icon text-3xl text-gray-50"
            />
          </Link>
        </div>
      </nav>

      {/* Mobile Menu Dialog */}
      <Dialog
        className="lg:hidden"
        open={mobileMenuOpen}
        onClose={() => setMobileMenuOpen(false)}>
        <div className="fixed inset-0 z-10" />
        <DialogPanel className="fixed inset-y-0 right-0 z-10 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-100">
          <div className="flex items-center justify-between">
            <Link
              to="/"
              className="-m-1.5 p-1.5"
              onClick={() => setMobileMenuOpen(false)}>
              <img className="h-8 w-[80px] h-[80px]" src={logo} alt="" />
            </Link>
            <button
              type="button"
              className="rounded-md text-xl font-bold p-2.5 text-gray-950"
              onClick={() => setMobileMenuOpen(false)}>
              <span className="sr-only">Close menu</span>
              <XMarkIcon className="h-8 w-8" aria-hidden="true" />
            </button>
          </div>
          <div className="mt-6 flow-root">
            <div className="-my-6 divide-y divide-gray-500/10">
              <div className="space-y-2 py-6">
                {/* Mobile Menu Links */}
                <div className="flex justify-between items-center">
                  <Link
                    to="/"
                    className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 hover:bg-gray-50"
                    onClick={() => setMobileMenuOpen(false)}>
                    {t("Home")}
                  </Link>
                  <Link
                    to="/products"
                    className="text-gray-800 text-3xl"
                    onClick={() => setMobileMenuOpen(false)}>
                    <TbBrandShopee title="Shop Now" />
                  </Link>
                </div>
                <Disclosure as="div" className="-mx-3">
                  {({ open }) => (
                    <>
                      <DisclosureButton className="flex w-full items-center justify-between rounded-lg py-2 pl-3 pr-3.5 text-base font-semibold leading-7 hover:bg-gray-50">
                        {t("Products")}
                        <ChevronDownIcon
                          className={classNames(
                            open ? "rotate-180" : "",
                            "h-5 w-5 flex-none"
                          )}
                          aria-hidden="true"
                        />
                      </DisclosureButton>
                      <DisclosurePanel className="mt-2 space-y-2">
                        {[...products, ...callsToAction].map((item) => (
                          <Link
                            key={item.name}
                            as="a"
                            to={item.to}
                            className="block rounded-lg py-2 pl-6 pr-3 text-sm font-semibold leading-7 hover:bg-gray-50"
                            onClick={() => setMobileMenuOpen(false)}>
                            {t(item.name)}
                          </Link>
                        ))}
                      </DisclosurePanel>
                    </>
                  )}
                </Disclosure>
                <Link
                  to="/dataSheet"
                  className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 hover:bg-gray-50"
                  onClick={() => setMobileMenuOpen(false)}>
                  {t("Features")}
                </Link>
                <Link
                  to="/aboutUs"
                  className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 hover:bg-gray-50"
                  onClick={() => setMobileMenuOpen(false)}>
                  {t("AboutUs")}
                </Link>
                <Link
                  to="/contactUs"
                  className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 hover:bg-gray-50"
                  onClick={() => setMobileMenuOpen(false)}>
                  {t("ContactUs")}
                </Link>
                {/* Language Dropdown */}
                <div className="relative langs mt-4">
                  <button
                    onClick={toggleDropdown}
                    className="w-full bg-white border-2 border-[#1963A6] text-gray-800 py-2 px-4 rounded-lg shadow-lg flex justify-between items-center">
                    <span>
                    Lang
                    </span>
                    <svg
                      className={`w-4 h-4 transition-transform transform ${
                        isOpen ? "rotate-180" : "rotate-0"
                      }`}
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg">
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M19 9l-7 7-7-7"></path>
                    </svg>
                  </button>
                  {isOpen && (
                    <ul className="absolute w-full bg-white border-2 border-[#1963A6] rounded-lg mt-2 shadow-lg z-10">
                      <li
                        onClick={() => {handleChangeLanguage("en")}}
                        className={`cursor-pointer py-2 px-4 hover:bg-[#1963A6] hover:text-white `}>
                        English
                        </li>
                      
                      <li
                        onClick={() => {handleChangeLanguage("ar")}}
                        className={`cursor-pointer py-2 px-4 hover:bg-[#1963A6] hover:text-white `}>
                        العربية
                        </li>
                      
                    </ul>
                  )}
                </div>
              </div>
            </div>
          </div>
        </DialogPanel>
      </Dialog>
    </header>
  );
}
