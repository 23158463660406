import { Route, Routes, useLocation } from "react-router-dom";
import "./App.css";
import "./tailwind.config";
import i18n from "i18next";
import { useTranslation, initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import httpApi from "i18next-http-backend";
import cookies from "js-cookie"
// import dotenv from "dotenv";

// Imported Pages
import Header from "./Components/Header/Header";
import Footer from "./Components/Footer/Footer";
import Home from "./Pages/Home/Home";
import AboutUs from "./Pages/AboutUs/AboutUs";
import ContactUs from "./Pages/ContactUs/ContactUs";
import Products from "./Pages/Products/Products";
import Terms from "./Pages/Terms/Terms";
import FloatingBtn from "./Components/floating-button/FloatingBtn";
import DataSheet from "./Data_sheet/DataSheet";
import { useEffect, useState } from "react";
import ScrollToTop from "./Components/ScrollToTop/ScrollToTop";
import SingleLogo from "./images/singleLogo.png"
import TextOfLogo from "./images/textOfLogo.png"
import MoreAboutUs from "./Pages/MoreAboutUs/MoreAboutUs";

// i18next.use(httpApi).init(i18nextOptions);

//* React-i18next Library functions
i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .use(httpApi) // passes i18n down to react-i18next
  .init({
    lng: "en",
    fallbackLng: "en",
    detection: {
      order: [
        "cookie",
        "htmlTag",
        "querystring",
        "localStorage",
        "navigator",
        "path",
        "subdomain",
        "sessionStorage",
      ],
      caches: ["cookie"],
    },
    backend: {
      loadPath: "/locale/{{lng}}/translation.json",
    },
  });

function App() {
  // dotenv.config();
  const { t } = useTranslation();
  const lang = cookies.get("i18next") || "en";

  useEffect(() => {
    window.document.dir = i18n.dir();
  }, [lang]);

  // State for header collapse
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const handleLoad = () => {
      setIsLoading(false);
    };

    if (document.readyState === "complete") {
      handleLoad();
    } else {
      window.addEventListener("load", handleLoad);
    }

    return () => {
      window.removeEventListener("load", handleLoad);
    };
  }, []);

  return (
    <div className="App">
      {isLoading ? (
        <div class="flex justify-center items-center flex-col min-h-screen">
          <img className="animate-slide-spin w-[200px]" src={SingleLogo} alt="logo"/>
          <img className=" w-[200px]" src={TextOfLogo} alt="logo" style={{marginTop:"10px"}}/>
        </div>
      ) : (
        <>
          <Header />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/aboutUs" element={<AboutUs />} />
            <Route path="/contactUs" element={<ContactUs />} />
            <Route path="/products" element={<Products />} />
            <Route path="/terms" element={<Terms />} />
            <Route path="/moreAboutUs" element={<MoreAboutUs />} />
            <Route path="/dataSheet" element={<DataSheet />} />
          </Routes>
          <Footer />
            <FloatingBtn />
            <ScrollToTop />
        </>
      )}
    </div>
  );
}

export default App;
