import React, { useEffect } from 'react';
import "./MoreAboutUs.css";

//* Imported Images
import img_1 from "./images/img_1.jpg"
import img_2 from "./images/img_2.jpg"
import img_3 from "./images/img_3.jpg"
import img_4 from "./images/img_4.jpg"
import img_5 from "./images/img_5.jpg"
import InnerTank from "./images/innerTank.jpg"
import Tuupe from "./images/tuupe.jpg"
import Drain from "./images/drain.jpg"

//* Video 
import Video from "./video/solarWH_video.mp4"
import { useTranslation } from 'react-i18next';

// Aos
import Aos from "aos";
import "aos/dist/aos.css";

const MoreAboutUs = () => {
  const [t] = useTranslation("global");

  useEffect(() => {
    Aos.init( {
    duration: 1300,
  })
  }, [])

  const ContentAboutProducts = [
    {
      id: 1,
      title: "Non-Pressurized Heater",
      description: "Stellar Solar Heater 300L is a compact and stylish non-pressurized heater with a digital control system.",
      image: img_1,
    },
    {
      id: 2,
      title: "Non-Pressurized Heater",
      description: "Stellar Solar Heater 300L is a compact and stylish non-pressurized heater with a digital control system.",
      image: img_2,
    },
    {
      id: 3,
      title: "Non-Pressurized Heater",
      description: "Stellar Solar Heater 300L is a compact and stylish non-pressurized heater with a digital control system.",
      image: img_3,
    },
    {
      id: 4,
      title: "Non-Pressurized Heater",
      description: "Stellar Solar Heater 300L is a compact and stylish non-pressurized heater with a digital control system.",
      image: img_4,
    },
    {
      id: 5,
      title: "Non-Pressurized Heater",
      description: "Stellar Solar Heater 300L is a compact and stylish non-pressurized heater with a digital control system.",
      image: img_5,
    },
    {
      id: 6,
      title: "Non-Pressurized Heater",
      description: "Stellar Solar Heater 300L is a compact and stylish non-pressurized heater with a digital control system.",
      image: InnerTank,
    },
    // {
    //   id: 7,
    //   title: "Non-Pressurized Heater",
    //   description: "Stellar Solar Heater 300L is a compact and stylish non-pressurized heater with a digital control system.",
    //   image: Tuupe,
    // },
    // {
    //   id: 8,
    //   title: "Non-Pressurized Heater",
    //   description: "Stellar Solar Heater 300L is a compact and stylish non-pressurized heater with a digital control system.",
    //   image: Drain,
    // },
  ];

  return (
        <div  style={{textShadow:"0 3px 10px #111", letterSpacing:"1px"}}>
            <section className="bg-gray-900 text-white mb-5">
  <div className="mx-auto max-w-screen-xl px-4 py-8 sm:px-6 sm:py-12 lg:px-8 lg:py-16">
    <div className="mx-auto max-w-lg text-center">
            <h2 data-aos="fade-up" className="text-3xl font-bold sm:text-4xl">{ t("Everything you want to know about our product details")}</h2>

      <p data-aos="fade-up" className="mt-4 text-lg text-gray-300">
        {t("Discover the dimensions of quality and elegance that highlight each of our products, with details that offer you an unforgettable experience.")}
      </p>
    </div>

    <div className="cards mt-8 grid grid-cols-1 gap-8 md:grid-cols-2 lg:grid-cols-3">
      
            {
                ContentAboutProducts.map((product) => {
                  return (
                    <a
                      data-aos="fade-up"
                    className="w-full h-[500px] block rounded-xl border border-gray-800 p-6 shadow-xl transition hover:border-blue-500/10 hover:shadow-blue-500/20"
                    >
                      <img className='w-[100%] h-[50%]' style={{backgroundSize:"cover", backgroundRepeat:"no-repeat", backgroundPosition:"center"}} src={product.image} alt="Stellar"/>
                      <h2 className="mt-4 text-2xl font-bold text-white">{ product.title}</h2>
                      <p className="mt-2 text-medium text-lg text-gray-300">
                            { product.description}
                      </p>
            </a>
                  )
                })
              }
    </div>
  </div>
      </section>
      
      <section className='video flex flex-col justify-center items-center min-h-screen bg-slate-300 m-4 text-center'>
  <div data-aos="fade-up" className='title mb-4'>
    <h2 className='text-3xl text-gray-950 mb-2 font-semibold'>{t("See the product in real life")}</h2>
          <p className='text-gray-800 text-lg'>{ t("Discover the beauty of the details by seeing the product in real life, to feel its quality and details up close.")}</p>
  </div>
  <video data-aos="fade-up" className="w-[800px] h-[450px]" style={{ backgroundSize: "cover", boxShadow:"0 5px 10px #111" , outline: "2px solid #111", backgroundRepeat: "no-repeat", backgroundPosition: "center" }} autoplay loop controls muted>
    <source src={Video} type="video/mp4" />
  </video>
      </section>

    </div>
  )
}

export default MoreAboutUs;